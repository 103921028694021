<template>
    <div class="mr-2 fill-current">
        <svg
            version="1.1"
	        id="svg875"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
	        xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 15.8 9.8"
	        style="enable-background:new 0 0 15.8 9.8;"
            xml:space="preserve"
            width="10"
            height="10"
            :class="active ? 'transform rotate-180' : ''"
        >
            <g id="layer1" transform="translate(-112.88258,-124.43492)">
                <path id="path4575" d="M128.6,125.3c0-0.5-0.4-0.9-0.9-0.9c-0.3,0-0.6,0.1-0.7,0.3l-6.2,6.7l-6.2-6.7c-0.3-0.4-0.9-0.4-1.3-0.1
                    c-0.4,0.3-0.5,0.9-0.1,1.2c0,0,0,0,0,0l7.6,8.3l7.6-8.3C128.5,125.7,128.6,125.5,128.6,125.3L128.6,125.3z"/>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        props: ['active','theme'],
    }
</script>