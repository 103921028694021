<template>

    <label :for="name" class="inline-flex items-center cursor-pointer mr-6" @click.prevent="activeToggle">

        <span class="relative">

        <span class="block w-10 h-6 rounded-full shadow-2xl" :class="toogleBack"></span>

            <span
                class="absolute block w-4 h-4 mt-1 rounded-full shadow-2xl inset-y-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out cursor-pointer"
                :class="value?`right-0 mr-1 bg-${accent}-700`:`left-0 ml-1 bg-${accent}-400`"
            >

                <input :id="name" type="checkbox" class="absolute opacity-0 w-0 h-0 cursor-pointer" />

            </span>

        </span>

        <span :class="`text-${text} ml-2`">{{ $t(label) }}</span>

    </label>

</template>

<script>

    export default {

        props: ['theme','accent','name','label','value','text'],

        computed: {

            toogleBack: function() {

                let classes

                if(this.theme == 'dark') classes = 'bg-white'
                if(this.theme == 'light') classes = `bg-${this.accent}-200`

                return classes

            }

        },

        methods: {

            activeToggle() {

                this.$emit('toogleChange',{ name: this.name, value: !this.value } )

            }

        }

    }
</script>